<template>
  <module
    ref="module"
    id="sync-trades"
    title-icon="fa fa-compress"
    :title="$t('changelogpendingsynctrades')"
    :filters.sync="filters"
    api-url="ACCAPIURL_V4"
    url="/changelog/synctrades"
    >
    <template slot="table-columns">
      <el-table-column :label="$t('action')" width="120px" align="center" >
        <template slot-scope="{row}">
          <el-tooltip :content="`${$t('task_completed')}`" placement="top">
            <p-button type="primary" link v-on:click="syncDone(row.userid, row.id)">
              <i class="fa fa-cogs" style="font-size: x-large;"></i>
            </p-button>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="account" :label="$t('account')">

      </el-table-column>
      <el-table-column prop="userid" :label="$t('userid')" width="270">

      </el-table-column>
      <el-table-column prop="strat_name" :label="$t('strategytxt')">

      </el-table-column>
      <el-table-column prop="mat_server" :label="$t('mattxt')">

      </el-table-column>
      <el-table-column prop="entry_dt" :label="$t('date')" width="270">
        <template slot-scope="{row}">
          {{ getFormattedDate(row.entry_dt) }}
        </template>
      </el-table-column>
      <el-table-column>

      </el-table-column>

    </template>
    <template slot="filter-field">
      <fg-input v-model="filters.strat_name" :label="$t('strategytxt')"></fg-input>
    </template>
  </module>

</template>
<script>
  import PPagination from "@/components/UIComponents/Pagination.vue";
  import {Button, Modal} from "@/components/UIComponents";
  import {Tooltip} from "element-ui";
  import moment from "moment/moment";
  import swal from "sweetalert2";

  export default {
    components: {
      PPagination, [Button.name]: Button, Modal,
      [Tooltip.name]: Tooltip
    },
    data() {
      return {
        filters: {},
      }
    },
    methods: {
      syncDone(userid, row_id) {
        swal({
          title: this.$t('attentiontxt'),
          text: this.$t('areyousurestatement'),
          type: 'warning',
          showCancelButton: true,
        })
          .then(() =>  {
            this.$refs.module.inProgress = true
            this.$deleteSyncTradesEntry({userid: userid, row_id: row_id} )
              .then(
                resp => {
                  this.$toast.success(this.$t(resp.msg));
                  this.$refs.module.refreshList();
                  this.$refs.module.inProgress = false;
                },
                error => this.$toast.warning(this.$t(error.data.msg))
              );
          })
          .catch(err => {
            this.$toast.warning(this.$t('statementdeletefailed'))
          })
      },
      getFormattedDate(date) {
      return moment(date).format("YYYY.MM.DD HH:mm")
    },
    }
  }
</script>
<style scoped>

</style>
